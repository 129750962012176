import React from 'react';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import './style.scss'

class MapComponentHover extends React.Component {
constructor(props) {
super(props);

this.state = {
hoverText: '',
isMouseTooltipVisible: false,
};
}

// componentDidMount(){
// const hovers = document.querySelectorAll('.hit-state')

// console.log(hovers);
// hovers.forEach(hover => {
// const content = hover.dataset.content
// hover.addEventListener('mouseover',function(){
// console.log('data: ',content);
// this.setState(function() {
// return {hoverText: `hi`};
// });
// })
// })
// }

toggleMouseTooltip = () => {
this.setState(prevState => ({ isMouseTooltipVisible: !prevState.isMouseTooltipVisible }));
}
updateText = (e) => {
const text = e.target.dataset.name
const textEle = document.querySelector('span.text')
const cirlcleEle = document.querySelector('span.circle')
const parent = e.target.parentElement

textEle.innerHTML = parent.dataset.name
setTimeout(() => {
// e.target.classList.add('active')
textEle.classList.add('active')
cirlcleEle.classList.add('active')
}, 250);
}
clearText = (e) => {
const parent = e.target.parentElement
const textEle = document.querySelector('span.text')
const cirlcleEle = document.querySelector('span.circle')
setTimeout(() => {
console.log(parent);
parent.classList.remove('active')
textEle.classList.remove('active')
cirlcleEle.classList.remove('active')
}, 250);
}
render() {
return (
<section className="MapComponentHover" onMouseEnter={this.toggleMouseTooltip} onMouseLeave={this.toggleMouseTooltip}>
  <MouseTooltip visible={this.state.isMouseTooltipVisible} offsetX={-15} offsetY={-15} className="mouse-follow">
    <div className="inside">
      <span className="circle"></span>
      <span className="text">{this.state.hoverText}</span>
    </div>
  </MouseTooltip>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink" viewBox="0 0 1923.89 1084.4">
    <g id="LandMass">
        <rect class="cls-2" x="1.91" y="1.61" width="1920" height="1080"/>
        <path class="cls-3" d="M356.26,595.13l318-28.68s84.69-7.34,91.34-7.34A114,114,0,0,1,781,560.56l-.6,3.53a118.58,118.58,0,0,0-23.41,0c-11.64,1.33-85.46,8.21-85.46,8.21l-7.46,66.93-4,6.33Z" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M830.58,715.4l51.76,8.31-2.66,15-10.65,6S857.51,732,856.46,732s-29,.33-29,.33Z" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M875.35,746.33l27.94,7.31-3.66,8-24.28,3-4.65-9.15v-6.15S870.37,745,875.35,746.33Z" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M921.24,608.21c-2.32-3.21-44.89-17.85-44.89-17.85s3-5,1.33-5.34-19.17-12.34-22.78-11.59-29.91,3.64-34.64,8-20.9,27.29-20.9,27.29,25.45,26.91,40.08,32.4,42.17,7.13,42.17,7.13,1-13,5.38-14,26.94-2,26.61-5.65-2.67-12-1.67-13.64,7.65-1.48,7.65-1.48Z" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M926,470.12l-2.08,18.82,17.63,2.66s-18.62,25.16-29.6,29.37-20.28,5.21-22.61,4.22-19-10.31-19-10.31-12,16.93-16.29,15.62-8.18-3.32-11.07-1.66-9.15,3.31-11.84,1.66-12.19,6.33-15.09,6-4.9,0-1.9-6,2.14-14.45,1.9-17.36.36-10.42,9.87-9.83,13.17,0,13.17,0L843,474.53l34.1,3.1s17.71-11,24.75-10.42S926,470.12,926,470.12Z" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M986,110.42l7.42,4.74s-3,5,0,9.31,8.07,18,10.52,21.95,17.75,10.31,22.41,18.62,11.21,9.35,11.21,9.35l-1.19,8-28-4-3,18,20.95,4.33-1.67,21h-11l-.8,4.76-7.11-1.2,1.26-6.22-47-7.32v5.65l2.81,2-.33,5.32,10,3.32-3,23.28,7.2,9.31s-.72,8.45-1.7,8.3-49.73-8.59-49.73-9.94,6.65-20,4-27.95a107.24,107.24,0,0,0-6-14.3s7-2.66,10.64-1.66-1.33-22.84-1.33-22.84,3.33,1.11,3-2.49-2.66-18.9-2.66-18.9Z" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M1026.18,336.53l24.09,3s-1.83,28.06-4,27.56-23.34-3.65-23.34-3.65l3.24-26.88" transform="translate(1.91 1.61)"/>
        <polygon class="cls-3" points="1056.01 341.9 1051.3 368.68 1063.99 370.67 1067.65 344.82 1056.01 341.9"/>
        <polygon class="cls-3" points="1206.65 382.64 1202.83 408.58 1228.93 412.48 1232.26 384.8 1207.36 380.84 1206.65 382.64"/>
        <path class="cls-3" d="M895.14,530.5l6.82-2s4.65,17,7.31,18.13,15.13,6.15,15.13,6.15l9.65-7.74,3,2.08s-9.76,11.18-14.3,12-16.63-8.32-19.12-12.81S895.14,530.5,895.14,530.5Z" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M818.16,309.69c-1,.18-23.95-3.65-23.95-3.65s.67,14,0,14H782.74s-1.83-9.15-1.33-13S778,289.41,778,289.41h-1.89s-6.82-10.33-5.82-11.9,2.49-2.14.83-5.18-10.47-26-10.14-28.64,1.16-3.32,1.16-3.32,19.12,0,22.28.83,17.63,8,17.63,8l17.74-7.32,8.19,1.83s2.64-11.1,2.07-11.95-39-23.13-39-23.13l-38.24,3.32L742.5,226.07l15.63,26.27,9.31,22.58-.33,5.18,2.16,2.82-.33,1.84,4,6.45,3.16,6-1,6.15,2,1.5s-3.83,21.69-1.5,27.06a109.44,109.44,0,0,1,5.15,15.17c.67,2.83.5,10.31.5,10.31h7s-3.16-12.49-3.66-16.31-1.66-11-1.66-11l-.34-5.82,11.48,1,.16,9,21.35,3.55Z" transform="translate(1.91 1.61)"/>
        <polygon class="cls-3" points="873.61 255.28 890.74 290.36 890.74 286.2 893.02 284.53 878.28 255.28 873.61 255.28"/>
        <polyline class="cls-3" points="895.94 284.53 901.71 284.63 901.71 289.03 906.36 289.03 906.36 296.18 901.15 295.12 899.87 297.97 894.35 295.12 895.94 284.53"/>
        <path class="cls-3" d="M1049.39,181.34s9.69,15.13,12.35,14.46,4-5,4-5l5,.5-.66,5.32L1066.9,201l17.95,19.95v13.8l19.62,8.65,3.33-3.49,6.48,2.66-1.83,10.14L1089,246s.17,9.2,2.83,11.53S1105.3,263,1105.3,263l-1,5s-8.82-4.66-11.48-5.49-3.82-4.16-3.82-4.16l-3.82-.16s1.83-11.14.33-13.64-8.65-2.49-8.65-2.49l-.16-19.95s-29.59-26.7-29.5-27.36,2.18-13.38,2.18-13.38" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M1107.8,0s99.76,74.59,119,84.57,11.31,12.63,11.31,12.63l-37.25-19.28s-87.11-67.84-93.1-62.52S1089.18,32,1089.18,32l20,27.93-20,14.63-20-24.27V29.7S1097.82,17.19,1107.8,0Z" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M279.11,331.94c-5.32,2.7-9.31,5.14-20.62,4.59s-43.9-16.47-50.55-14.17-21.28-2.7-21.28-2.7-11.31,5.44-8,15.86,2.19,27.42,8,28.54,7,5.5,3.49,6.83-14.8-1-15.46-11.31-8-23.05-8-23.05l2.66-24.28L201.29,301s63,15.89,71,18.63S279.11,331.94,279.11,331.94Z" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M1109.13,309.86" transform="translate(1.91 1.61)"/>
        <path class="cls-3" d="M1106.27,312.25l20.55,11.47s21.41-17.82,12.66-27.36c-13.72,1.69-33.21,15.89-33.21,15.89" transform="translate(1.91 1.61)"/>
        <polyline class="cls-3" points="1017.24 410.08 1021.26 410.08 1019.25 398.44 1045.2 414.07 1050.19 410.08 1058.08 415.99 1045.2 432.9 1035.56 426.37 1025.52 438.34 1014.48 428.4 1017.24 410.08"/>
        <polygon class="cls-3" points="274.25 1011.98 281.46 987.87 307 1004.93 296.98 1028.93 274.25 1011.98"/>
        <path class="cls-4" d="M993.74,101.86c3.06-1.33,10.11-6.08,11.64-9.64,4-9.31,56.53-54.87,63.84-62.52,3.4-3.55,13.64-8.65,21.62-11.31S1107.47.44,1107.8,0H0L-.8,359.58s15.87-2.33,25.51-6.65,48.89-4.32,61.52-7.2,12.57-10.48,18.29-10.09c14.75,1,75.27-20.73,82.14-22.61,5.89-1.62,10.64-3.66,42.23,7.31s50.82.19,65.85,0c25.93-.33,112.4-21.28,131.68-29.26,9.43-3.9,31.26-1,44.23-1s16,8.65,26.94,9.62,43.23-15.94,47.88-20.26,16.63-4.66,23.61-4.28,29.27-2.7,35.25-5.7,1.33,2.33,11.64,5.46,58.2-26.08,66.84-29,20.29,3.55,33.92,3.88,39.1-13.06,41.57-19.28c8.31-20.95,44.28-17.54,33.59-13.86-7.1,2.44-16,8-4.33,10.86a38.73,38.73,0,0,0,13.64,0c7.64-1.33,18.29,2.66,24.74,4.33,3.72,1,10.84-2.33,18.55-5.33s12.91-6.31,18.56-8.31,41.23-30.59,43.44-32.59,4.78,1.66,7.77,1.66,15.63-14,18.62-16.62a6.4,6.4,0,0,1,6-1.66s15.7-15.67,19-20.29C964.36,139.77,989.81,103.57,993.74,101.86Z" transform="translate(1.91 1.61)"/>
        <path class="cls-4" d="M856.15,492.1c-2.49.72-7.07-5.68-9.48-5.07-2.66.66-4.11,0-3.32-2,1.58-4-.16-9.65,5.07-8.64,10.1,1.93,9.64-.25,13.05,1.41C865.15,479.59,861.33,490.61,856.15,492.1Z" transform="translate(1.91 1.61)"/>
    </g>
    <g id="Major_roads" data-name="Major roads">
        <g id="Minor_roads" data-name="Minor roads">
            <line class="cls-5" x1="57.55" y1="1055.54" x2="138.38" y2="565.28"/>
            <path class="cls-5" d="M186.66,372.22a4.81,4.81,0,0,0,3.49-1.33c1.5-1.5,3.66-10.31,4.33-15.3s.49-10.74,5.48-9.86,49.72,9.2,53.54,9.53,108.58-21.12,113.4-20.95,17.29,2.49,17.29,2.49L285.86,990.48l48.51,32.42s248.68,37.34,255.66,38.34,21.29,2,27.6,3,80.43,13.39,80.43,13.39l2.53-16.47s9.49.75,15.14,1.75S737,1080,737,1080" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M911.93,1080s56.2-365.93,56.53-368.59,8.65-12.64,8.65-12.64,5.32,3.16,8.48,3.16,20.12-5.15,24.28-4.65,268.63,44.17,301,48.6,162.72,21.73,181.35,24,317.07,51,327.22,52.32,82,13.46,84.46,13.8,9.92,3.82,16.1,4.49" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1920,192.09s-9.78-1.44-12.94-2.1-7.81-4-11.3-4.49-276.51-41.41-283.33-42.57-272.85-39.41-278.17-40.07-33.48-5.66-33.48-5.66" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1920,408.46s-33.89-5.81-38-5.65-12.33,2.81-17.39,2.07l.68-4.41s-176.16-30.58-194.12-33.57-259.38-38.25-275.67-40.57-42.73-6.49-44.73-6.65-5.49.66-6.15,5.48-7.15,53.71-8.48,58.2-5.32,10.47-8.48,12-8.81,2.33-16.13,1.16-81.14-13.3-81.14-13.3-73.83-11.73-75.49-12.06S1147,367,1147,367l-.89,6.05s6.82-1.73,8.76-1.95" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="867.18" y1="476.14" x2="899.87" y2="258.3"/>
            <line class="cls-5" x1="813.31" y1="367.07" x2="882.17" y2="376.24"/>
            <line class="cls-5" x1="817.98" y1="333.55" x2="886.85" y2="342.73"/>
            <line class="cls-5" x1="835.57" y1="297.97" x2="879.69" y2="303.85"/>
            <line class="cls-5" x1="839.41" y1="273.94" x2="878.29" y2="279.12"/>
            <line class="cls-5" x1="822.91" y1="301.81" x2="835.26" y2="303.46"/>
            <line class="cls-5" x1="829.87" y1="260.45" x2="842.21" y2="262.1"/>
            <line class="cls-5" x1="880.68" y1="299.67" x2="893.02" y2="301.31"/>
            <line class="cls-5" x1="846.4" y1="323.97" x2="844.91" y2="337.14"/>
            <line class="cls-5" x1="821.68" y1="309.66" x2="834.03" y2="311.3"/>
            <path class="cls-5" d="M953.79,474.53s22.1-150.31,22.77-151.86a93.77,93.77,0,0,0,2.49-12.81" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1030,311.24s-5.34-.54-8.22-1.87-12.19-9.31-15.07-10.64-20-8.43-22.17-10.2-4.88-6.87-8.87-7.32S961,279.44,961,279.44" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M962.37,266.8s-3.55,29.71-4.21,33.26-1.11,7.76,13.08,9.53,39,5.54,42.12,6,7.09-3.32,8.42-6.2" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1032.68,293.51c-1.59-.33-2.92.12-6.46-1S999.83,281,998.5,279.44s-.88-7.11-.88-7.11" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M872.85,339.5s7.08-47.59,7.32-50.33a11.55,11.55,0,0,0-1.24-6.74c-1-1.91-6.82-13.88-7.32-14.88a7,7,0,0,0-3.74-2.91c-1.33-.25-29-4.16-29-4.16s-7.49,50.47-7.65,53.38.06,5.23.9,5.82a18,18,0,0,0,6.16,1.74c2.66.33,36.41,5.76,36.41,5.76" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="1291.06" y1="1081.61" x2="1494.13" y2="771.44"/>
            <path class="cls-5" d="M1542.15,777.53c.17-2.83,31.18-206.17,31.18-206.17" transform="translate(1.91 1.61)"/>
            <polyline class="cls-5" points="375.84 406.65 312.5 396.77 321.39 344.58"/>
            <line class="cls-5" x1="370.63" y1="441.25" x2="686.72" y2="488.56"/>
            <path class="cls-5" d="M1622.87,578.92s24-155.16,23.31-159.15-8.66-5.65-11.64-10-4.67-14.29,0-18.62,13.31-7.31,15.63-11,4.41-15.84,4.41-15.84" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1157.76,293.13c1.66-.44,85,11.25,85,11.25l-31,208.76" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1074.1,390.06s64.29-81,66.62-83.69-1.24-10-1.24-10" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="1148" y1="371.66" x2="1086.14" y2="362.43"/>
            <line class="cls-5" x1="1301.26" y1="1.61" x2="1337.84" y2="51.93"/>
            <path class="cls-5" d="M974.21,675.74s9.11,7.11,6.89,15.72c-1.49,5.78-4,7.31-4,7.31" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1035.75,273.23,1069.22,44s-13.63,7.32-14.63,12.64-10,16-14.3,19.62S1022.55,92.94,1019.76,96s-12.06,10.06-11.06,13.72,12.79,15,10,16.71c-5.16,3.08-6.9,6.65-6.9,6.65l-3.15,7s13.37,6,18,11.31,4.37,13,8.34,14,3.3,5,2,12.3-6,67.84-12.31,74.16-10.76,7.83-17,7.14-19.26-2.27-23.27-3.57c-3-1-8.65-5.9-7.65-13.88s4-20.62,4-20.62l49.6,8.39" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1109.13,496.9c-.11,0-10.2,67.19-10.2,67.19l-105.15-17" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1075.83,708c-.25,0,13.35-77.85,13.35-77.85L983.6,613.49" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M986.48,594.65l84.3,13.56s2.21-12.28,2.21-13.56,2.89-3.51,2.89-5.06.44-5.1,1.33-6,4.87-8.64,5.09-13.08a22.76,22.76,0,0,1,2.64-8.69" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1008.81,538.27l-.77-1.56c-.78-1.55.44-4.87.88-8s1-7.76,1-7.76a31.12,31.12,0,0,0,6.54,2.11c2.55.33,32.93,5.32,32.93,5.32s-.56,5.77-2.45,8.31-5.54,6.32-6,8.34-1,9.49-.69,9.53" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1003.92,548.69s-.43-9,7.22-11.42,7.87-2.77,11.09-2.33,17.07,2.46,18.73,2.84a3.71,3.71,0,0,1,2.33,2.22" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1014,550.33s1.12-4.48,1.34-5.28,5-4.87,6.43-5,8.2.82,11.31,1.15,6.54.67,8.42,2.6" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M910.27,259.66,904,301l15.3,1.21L916.59,324s9,2.66,10.64,0,2.66-8.39,2.66-8.39l46.67,7.06" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M972.92,345.73,961,343.21l-3.14,24.68-35.66-5.65s1.66-18.87,3.66-22.74a11.48,11.48,0,0,1,6-5.19L927.23,324" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M907.54,277.51s6.89.4,9.21,0,4.66-4.5,7.82-4.28,18.79,2.52,21.95,4.28.16,11.12,0,16-.34,12-4.83,11.37-6.48-1.66-11.47-1.5a52.67,52.67,0,0,1-11-1.14L904,301l-11.52-7.52" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M888.51,319.66c0,.11,28.08,4.34,28.08,4.34" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M931.88,334.31s3,6.46,5.16,6.8,24,2.1,24,2.1" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M974.9,478.52s.88-19.51,3.54-22.67,12.3-6.48,13.14-12.14.33-19.12,4.15-21.45,4.91-3.65,8.19-3.65h9.1" transform="translate(1.91 1.61)"/>
            <polyline class="cls-5" points="858.68 338.97 855.49 361.52 848.84 360.69 847.74 371"/>
            <line class="cls-5" x1="885.57" y1="353.59" x2="857.63" y2="349.72"/>
            <path class="cls-5" d="M813.42,352s-16.88-.05-18.87-.88-6-8.22-6-12.34-.67-40.71-.67-40.71-9-21.44-9.14-21.94-3.49-10-3.82-10.64-5.66-9.48-5.66-9.48,3-8.31,8.71-8.48,12.57,5,18.73,6.32S829.57,246,829.57,246s.79-3.6-2.4-4.43-21-2.33-24.14-3.66-3.65-4.32-7.07-5.32-8.73-1.33-9.56-3-6.32-7.65-6.32-7.65" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="827.85" y1="260.45" x2="780.66" y2="277.72"/>
            <line class="cls-5" x1="821.68" y1="305.66" x2="790.64" y2="301.61"/>
            <line class="cls-5" x1="792.64" y1="273.94" x2="803.12" y2="303.24"/>
            <path class="cls-5" d="M925.23,259.66s3.66-26.28,4-29.27-7-13.3-7-13.3.83-3.49,12-2.66,46.56,6.48,46.56,6.48" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M860.46,250.81s2.53-9.28,4.81-10.61,39.51-16.46,39.51-16.46,4.33,10.19,3.83,13.24-1.33,7.38-1.33,7.38L927,246.84" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M888.51,253.5c-.35-.66-3.58-21.64-3.58-21.64" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="909.19" y1="245.97" x2="888.35" y2="243.1"/>
            <line class="cls-5" x1="993.35" y1="258.46" x2="991.75" y2="271.41"/>
            <line class="cls-5" x1="1033.69" y1="223.66" x2="1043.87" y2="225.35"/>
            <path class="cls-5" d="M1045.12,201l-10.59-1.66v-2.83s-19.51-3.16-21-3.33-1.49-4.15-1-6.31,1-3.33,3.49-3.16,19.87,3,19.87,3" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1036.72,166.22s2.74-5.33,3.24-9.16,9.64-72.16,10.48-74.49,10.8-10.47,11.8-10.64,3-.64,3-.64" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1014.86,100.69c.66.67,10,14.81,13,16s17.3,3,17.3,3" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1024.41,91.29c2.58,3.75,7.24,13.4,10.23,14.4s12,1.88,12,1.88" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="1044.68" y1="75.53" x2="1052.35" y2="84.19"/>
            <line class="cls-5" x1="1058.05" y1="135.26" x2="1020.59" y2="128.08"/>
            <path class="cls-5" d="M1057.61,123.51s45.53-13.5,50.19-11.84,33.59,44.23,35.91,46.22,6.32,4,9.81,3.83,8.65,1.27,11.14-3.1,26.27-47.66,27.11-51.05.49-10.7-3-14.35S1151,60.29,1141.55,57.63s-16.29-4.49-20.12-2.49-35.74,25.44-35.74,25.44" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1067.45,56.15c0,.23,25.55,32.91,27,36.07a9.14,9.14,0,0,1-1.83,9.64c-2.16,2-22.34,7.15-25.22,7.48s-7.58-1.28-7.58-1.28" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1063,86.32s3.19,1.08,4.52.74,14.91-11.27,14.91-11.27" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1090,85.7s-14.82,10.5-17.19,11.5-11.3,0-11.3,0" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1082.47,105.62s4.55,5,4.71,6.38a20.22,20.22,0,0,1,0,3.36" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1053.14,154.17c1.29.07,83.76,12.05,83.76,12.05" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1139.76,152.8s-7.35,51.48-9.18,54.48-29.27,24.27-29.43,23.77-12.64-16.75-12.64-16.75" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1104.64,162.55s-3.66,37.74-7.81,42.9-19.62,18.29-22.29,18.29-30.9-4.56-30.9-4.56" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1080.43,279.44l4.43-29.43s-6.71-1-10.76,1.33-15.52,13.28-15.52,13.28" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1109.63,272.33s-18.45-2.64-34.53-5.08c-11.89-1.8-22.49-3.5-23.33-3.93-2-1-2.66-7-1.67-8.16s15.8-12.47,16.63-14.13,3.33-17.29,3.33-17.29" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="1075.13" y1="278.56" x2="1077.02" y2="268.86"/>
            <path class="cls-5" d="M1061.32,246c-1.74-.27-21.08-3.49-21.08-3.49" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1126.09,165.54s-5,33.09-5.65,35.42-6.66,7.48-8.82,7.48-14.79-3-14.79-3" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1090.89,211.48s-16.62-11.19-16.73-12.52a57.71,57.71,0,0,1,.94-7s-11.09-.78-12.06-3.77.86-19.29.86-19.29" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="1102.03" y1="196.68" x2="1077.02" y2="193.54"/>
            <line class="cls-5" x1="1094.27" y1="161.38" x2="1089.17" y2="194.76"/>
            <line class="cls-5" x1="1053.29" y1="167.83" x2="1091.86" y2="173.81"/>
            <line class="cls-5" x1="1097.93" y1="114.86" x2="1091.06" y2="160.91"/>
            <line class="cls-5" x1="1122.7" y1="128.36" x2="1117.03" y2="164.16"/>
            <path class="cls-5" d="M1070.66,120.48c-.11.5-4.72,35.48-4.72,35.48" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1092.68,135.59c0,.07,25.27,3.85,25.27,3.85" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="1103.71" y1="187.02" x2="1124.49" y2="189.99"/>
            <line class="cls-5" x1="1080.23" y1="375.05" x2="1024.86" y2="365.67"/>
            <path class="cls-5" d="M973.62,341.11c1.38.4,33.09,4.06,35.08,7s1.11,9.62,0,12.71-3.1,23.7-3.77,30.35-1,10-1,10" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M1015.11,403.32c-1.09-.4-49.66-7.72-49.66-7.72" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="1005.84" y1="472.59" x2="977.69" y2="469.01"/>
            <line class="cls-5" x1="980.35" y1="457.46" x2="959.04" y2="453.51"/>
            <polyline class="cls-5" points="990.22 448.43 980.36 443.77 960.83 441.35"/>
            <line class="cls-5" x1="997.65" y1="423.88" x2="964.29" y2="417.99"/>
            <line class="cls-5" x1="990.22" y1="400.68" x2="987.34" y2="420.93"/>
            <polyline class="cls-5" points="1009.19 371 984.23 367.22 980.36 399.18"/>
            <path class="cls-5" d="M1056.74,413.62l-39-30.43s-12.19-2-19.73-2.66S981,376.76,981,376.76" transform="translate(1.91 1.61)"/>
            <polyline class="cls-5" points="1010.62 362.43 984.9 356.2 990.22 323.97 1002.19 326.79 1003.08 315.38"/>
            <line class="cls-5" x1="948.97" y1="290.68" x2="961.43" y2="291.7"/>
            <path class="cls-5" d="M972.92,281.66c-.58.66-4.12,28-4.12,28" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="994.77" y1="295.12" x2="992.2" y2="312.86"/>
            <path class="cls-5" d="M930.67,274.92c.22.53-3.66,28.39-3.66,28.39" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M938.87,263.32c.22,1-1.55,11.6-1.55,11.6" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M869.69,277.51c-1.1.6-1.1,11.44-2.65,11.56s-31.72-4.57-31.72-4.57" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M983.6,608.21s-3.5.89-4.16,0-57.17-50.8-57.19-49.34-1.76,11.21,0,15.2,1.32,21.73.66,29.49-3.66,25.38-3.66,25.38" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="855.87" y1="573.91" x2="925.19" y2="599.18"/>
            <path class="cls-5" d="M953.79,585.45,948,597.57s-5.54-3.55-5.77-2.66-2.66,8.87-1.55,11.08,19.36,7.5,19.36,7.5,2.37-3.51,2.37-6.61,4.24-9.31,4.24-9.31" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M970.35,492l-47.07,45.74-4-3-6.1-13.8s14.41-9.86,15.74-12.3,8.65-7.32,8.65-7.32,9.31-8,11.08-14.41a36.69,36.69,0,0,0,1.11-12.41" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M949.72,610.23s2.95-8.23,1.7-9.56a42.05,42.05,0,0,0-3.46-3.1" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="961.91" y1="526.69" x2="939.45" y2="502.97"/>
            <path class="cls-5" d="M928.89,508.67l-34.8-7.32s-5.1,4.22-10,3.66-8.42-.77-8.2-2.33,10.19-14.63,8.86-17.73-16.84-10.42-16.84-10.42" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M832,471s-.89,25.5-4.21,28.16S813,506.45,813.17,510s3.54,10,2.66,12.19-8,9.6-8.2,19.54A148.37,148.37,0,0,1,805.19,563" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M834.23,576.51s1.56-3.32-3.65-4.21-21.18-7.1-21.18-7.1a62.79,62.79,0,0,0-13.3-3.37A73.7,73.7,0,0,1,781.43,558" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M797.87,441.94s-7.76-.41-9.53-2.2-4.21-6-4.44-7.55,2.35-6-5.92-7.32-41.74-5.65-45.51-6.26-8.78,3.5-8.2,3.65,4,5.71,6.65,6.38,35.48,5.46,40.57,6a24.62,24.62,0,0,0,12.41-2.43" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M759.47,422.26,758,432.8l-3.56-.51L750,461.39s-.92,12-1.59,12-19.73-6-19.73-6l-4.48,26" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M789,504c-.66-.22-107-17.49-107-17.49" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M758.26,473.52s-2.51,11.43-1.18,13.42,30.15,5.32,31,4.66,2-15.3,2-15.3-10-2.88-14.85-3.1-19.51.44-26.83.22" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M753,441.94c0-.12,31.16,4.88,31.16,4.88s-.45,8.31-1.33,9-31.34-4-31.34-4" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="771.19" y1="474.79" x2="769.71" y2="491.17"/>
            <line class="cls-5" x1="783.34" y1="476.14" x2="781.37" y2="492.69"/>
            <path class="cls-5" d="M744.87,496.75l-1.7,11.14,41,5.32-2.7,16.63H772.6s1.16-7.15-.17-7.15S731,517,731,517s-2.16,10.7-1,11,41.07,6.49,41.07,6.49l-.66,5.5s9.2-.35,9,1.48S777,557.11,777,557.11s-11-1.16-15.93-1.5-12.51-.66-12.62-2.82,1.06-10.31,1.55-10.31,18.55,2.57,18.55,2.57" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M724.88,558.84s6.32-43.46,6.82-47,1-6.14,3.15-5.73,8.32,1.74,8.32,1.74" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="769.71" y1="557.78" x2="776.52" y2="513.59"/>
            <path class="cls-5" d="M727.75,539.09c1.28.06,22.23,3.39,22.23,3.39" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M731.36,558.84s-20.28,1.76-26.43,1.93-13.31,1.16-13.31,1.16l-16.27-3.09" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M693.12,498.42s20.29,3.16,24.77,4.32,4.49,3.05,4,5.93-2.83,4.38-5.66,4.54S704.76,512,704.76,512s-1.15,8.22-1.41,9.88a4.92,4.92,0,0,0,.91,3.22s8.81-2.55,12.64-2.55S729,525.08,729,525.08" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M713.93,560.24s1.8-12.94,2.63-15.93,3.58-21.43,3.58-21.43" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="717.04" y1="493.52" x2="715.84" y2="502.97"/>
            <line class="cls-5" x1="698.08" y1="500.51" x2="695.03" y2="522.14"/>
            <line class="cls-5" x1="683.87" y1="520.15" x2="705.27" y2="523.47"/>
            <path class="cls-5" d="M694.64,561.56c.14.05,1.64-17.43,3.3-20a96.06,96.06,0,0,0,5.41-10.06,56.57,56.57,0,0,0,.91-6.42" transform="translate(1.91 1.61)"/>
            <path class="cls-5" d="M715.13,546c-.23,0-17.19-4.41-17.19-4.41" transform="translate(1.91 1.61)"/>
            <line class="cls-5" x1="706.84" y1="562.38" x2="708.45" y2="545.4"/>
            <line class="cls-5" x1="681.57" y1="529.99" x2="705.27" y2="533.12"/>
            <path class="cls-5" d="M684.81,560.39c.16-1.45,5.15-17.59,2.49-18.83S679,540,679,540" transform="translate(1.91 1.61)"/>
        </g>
        <g id="Major_Roads-2" data-name="Major Roads">
            <path class="cls-6" d="M1404.37,0l-68.45,50.32L1158.68,286.75s-32.59,226.13-36.91,241.76-18.63,88.13-78.48,125.37-101.76,37.24-119,37.58S741,657.54,721.39,654.88s-366.46-56.53-379.1-59.19S-.8,542.7-.8,542.7" transform="translate(1.91 1.61)"/>
            <path class="cls-6" d="M698.06,1077.63s43.94-287.41,47.27-302,8.2-26.6,18-34.36,24.16-28.6,23.72-40.79-12.86-44.12-10.87-51,36.14-64.74,44.12-68.06,100.65-20.62,107.08-24.17,78.26-76.93,78.26-76.93,193.09,31,203.07,32.37,135.45,22.61,154.52,26.16,396.39,61,411.46,63.18S1920,623.94,1920,623.94" transform="translate(1.91 1.61)"/>
            <line class="cls-6" x1="1921.91" y1="43.28" x2="1763.44" y2="1081.61"/>
            <line class="cls-6" x1="423.4" y1="1082.74" x2="1.11" y2="800.48"/>
            <path class="cls-6" d="M1144.79,294.63s-5.29-5.44-11.72-7.21-97.32-14.19-97.32-14.19l-.75,5c-4.14,27.35-24,158.73-25.19,167.07-1.33,9.31-5.1,25.71-5.76,29a7.77,7.77,0,0,0,1.55,6s64.29-83.13,68.5-90.23,17.29-57.19,25.94-70.4,20.84-16.28,34.14-21.38a152,152,0,0,1,23.86-7.07" transform="translate(1.91 1.61)"/>
            <path class="cls-6" d="M972.9,685.24c-.11-2.1,25.16-166,25.16-166s-12.61-11.86-16.12-14.9c0,0-12-10-11.59-12.26s11.53-9.31,10.2-11.75-75.16-13.74-78.7-13.08-13.08,6.88-16.85,7.32a96.65,96.65,0,0,1-19.73,0c-6.66-.89-40.13-6.21-44.79-6.43S796,465.36,796,465.36s-17.75-4-29.55-3.69-24.78-.17-32.26-3.66-14-13.3-15-18.37,1.33-11.39,3.16-13.05,3.33-5.82,3.33-8.32-1.33-11-1.5-12.47-9.7-.76-9.7-.76-17.73,12.74-19.06,15.84S663.1,646,663.1,646" transform="translate(1.91 1.61)"/>
            <path class="cls-6" d="M979.44,637.92l-60.19-9-4.32,24.27s-70.17-10.3-75.49-12.13-40.08-32.4-40.08-32.4-9.91-8-15.23-12.66-6.66-10.42-6-17.52,9.09-52.09,11.75-72.05,20-130.35,21.5-141S829.57,246,829.57,246L1035,278.2" transform="translate(1.91 1.61)"/>
        </g>
        <polygon class="cls-4" points="930.81 444.44 848.62 444.44 838.07 453.29 828.34 444.44 745.33 444.44 745.33 400.65 930.81 400.65 930.81 444.44"/>
        <g id="Drysdale_Town_Centre" data-name="Drysdale Town Centre">
            <circle class="cls-7" cx="990.56" cy="499.9" r="9.01"/>
            <path class="cls-8" d="M1023.45,498.83c0,3.23-2.46,5.25-6.3,5.25h-5.1v-10.5h5.1C1021,493.58,1023.45,495.61,1023.45,498.83Zm-1.29,0c0-2.68-2.11-4.08-5.1-4.08h-3.75v8.17h3.75C1020.05,502.92,1022.16,501.52,1022.16,498.83Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1035.9,504.08l-3.48-4h-5v4h-1.28v-10.5h7c2.34,0,4,1.17,4,3.25,0,1.83-1.32,3-3.25,3.19l3.58,4.06Zm-2.79-5.18c1.7,0,2.82-.65,2.82-2.07s-1.12-2.08-2.82-2.08h-5.7v4.15Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1039.68,493.58l4.41,5.69,4.38-5.69h1.5l-5.26,6.83v3.67h-1.26v-3.67l-5.28-6.83Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1062.12,501c0,1.93-2,3.36-5.38,3.36a9.25,9.25,0,0,1-6.14-2.16l.89-.95a7.53,7.53,0,0,0,5.32,1.94c2.54,0,4-.8,4-2.1s-1.54-1.59-4.38-1.83-5.49-.85-5.49-2.92,2.49-3.11,5.3-3.11a8.08,8.08,0,0,1,5.37,1.88l-1,.84a6.16,6.16,0,0,0-4.32-1.55c-1.88,0-4.07.51-4.07,1.89s2,1.58,4.46,1.77C1059.9,498.36,1062.12,499,1062.12,501Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1076,498.83c0,3.23-2.46,5.25-6.3,5.25h-5.1v-10.5h5.1C1073.52,493.58,1076,495.61,1076,498.83Zm-1.29,0c0-2.68-2.11-4.08-5.1-4.08h-3.75v8.17h3.75C1072.58,502.92,1074.69,501.52,1074.69,498.83Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1086.62,501.26h-7.08l-1.53,2.82h-1.4l5.78-10.5h1.41l5.77,10.5h-1.42Zm-.62-1.14-2.92-5.38-2.93,5.38Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1100.85,502.92v1.16h-9.3v-10.5h1.26v9.34Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1113.09,502.92v1.16h-10.15v-10.5h10v1.17h-8.74v3.41h7.41v1.17h-7.41v3.59Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1129.8,494.73h-5v9.35h-1.26v-9.35h-4.95v-1.15h11.17Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1130.43,498.83c0-3.41,2.4-5.56,6.39-5.56s6.39,2.15,6.39,5.56-2.4,5.57-6.39,5.57S1130.43,502.25,1130.43,498.83Zm11.49,0c0-2.81-2-4.39-5.1-4.39s-5.1,1.58-5.1,4.39,2,4.4,5.1,4.4S1141.92,501.66,1141.92,498.83Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1161.5,493.58l-3.86,10.5h-1.33l-3.38-9-3.39,9h-1.33l-3.86-10.5h1.38l3.17,9,3.4-9h1.28l3.39,9,3.18-9Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1174.85,493.58v10.5h-1.11l-8.82-8.89v8.89h-1.23v-10.5h1.36l8.57,8.66v-8.66Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1192.85,501.22l1.2.6a6,6,0,0,1-5.39,2.58c-3.91,0-6.31-2.16-6.31-5.57s2.4-5.56,6.36-5.56a6,6,0,0,1,5.34,2.58l-1.2.6a4.53,4.53,0,0,0-4.16-2c-3,0-5,1.5-5,4.39s2,4.4,5,4.4A4.47,4.47,0,0,0,1192.85,501.22Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1206.59,502.92v1.16h-10.16v-10.5h10v1.17h-8.75v3.41h7.41v1.17h-7.41v3.59Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1220.34,493.58v10.5h-1.11l-8.82-8.89v8.89h-1.23v-10.5h1.37l8.56,8.66v-8.66Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1233.59,494.73h-5v9.35h-1.26v-9.35h-4.95v-1.15h11.18Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1245.41,504.08l-3.48-4h-5v4h-1.27v-10.5h7c2.34,0,4,1.17,4,3.25,0,1.83-1.32,3-3.26,3.19l3.59,4.06Zm-2.79-5.18c1.69,0,2.82-.65,2.82-2.07s-1.13-2.08-2.82-2.08h-5.7v4.15Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M1259.69,502.92v1.16h-10.16v-10.5h10v1.17h-8.75v3.41h7.41v1.17h-7.41v3.59Z" transform="translate(1.91 1.61)"/>
        </g>
        <g class="cls-9">
            <path class="cls-8" d="M779.37,411.06v.11l-1,.6v9.47l1.91,1.22v.1h-7.53v-.1l1.92-1.22v-9.36h0l-5.91,10.92-6-10.28h0v8.69l1.92,1.22v.1h-4.14v-.1l1.92-1.22v-9.27l-.51-.88H766l4.58,7.75,4.23-7.75Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M813.73,411.06v.11l-1,.6v10.46h3.3a4.16,4.16,0,0,0,4.3-3.25h.14v3.55H807.17v-.1l1.92-1.22v-8.83l-1.92-1.21v-.11Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M859.28,410.83a10.84,10.84,0,0,1,3.7.61,7.72,7.72,0,0,1,2.86,1.79,5.64,5.64,0,0,1,1.17,1.66,4.45,4.45,0,0,1,.41,1.82,4.75,4.75,0,0,1-1.08,3,7.25,7.25,0,0,1-2.93,2.16,9.83,9.83,0,0,1-4,.81,9.53,9.53,0,0,1-3.93-.81,7.72,7.72,0,0,1-2.92-2.16,4.63,4.63,0,0,1-1.08-2.91,4.37,4.37,0,0,1,.64-2.27,6.41,6.41,0,0,1,1.71-1.92,7.92,7.92,0,0,1,2.53-1.32A8.93,8.93,0,0,1,859.28,410.83Zm.27.37a4.66,4.66,0,0,0-2,.34,2.47,2.47,0,0,0-1.18,1,4.71,4.71,0,0,0-.44,1.21,13.26,13.26,0,0,0-.17,1.49c0,.64-.06,1.29-.06,2,0,.37,0,.81.06,1.28s.1.88.14,1.22a3,3,0,0,0,1,1.79,3.83,3.83,0,0,0,2.62.75,4.28,4.28,0,0,0,1.85-.34,2.83,2.83,0,0,0,1.17-1.08,4,4,0,0,0,.44-1.19,9.33,9.33,0,0,0,.17-1.42c0-.61.07-1.25.07-1.93a10.92,10.92,0,0,0-.37-3.07,2.84,2.84,0,0,0-1.15-1.63A4.46,4.46,0,0,0,859.55,411.2Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M876.66,422.53v-.1l1.92-1.22v-8.83l-1.92-1.21v-.11h8.94a8.7,8.7,0,0,1,2.83.44,6.49,6.49,0,0,1,2.28,1.22,6.13,6.13,0,0,1,1.52,1.86,5.09,5.09,0,0,1,.53,2.34,4.79,4.79,0,0,1-.53,2.26,4.94,4.94,0,0,1-1.55,1.79,7.37,7.37,0,0,1-2.39,1.15,10.91,10.91,0,0,1-3,.41Zm5.61-11.16v10.86h2.79a3.59,3.59,0,0,0,2.12-.55,3.34,3.34,0,0,0,1.21-1.69,9.12,9.12,0,0,0,.41-3,11,11,0,0,0-.31-2.91,4.24,4.24,0,0,0-.94-1.83,2.52,2.52,0,0,0-1.07-.71,5.52,5.52,0,0,0-1.65-.2Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M902,422.63h-.13v-4.19h.27A10.9,10.9,0,0,0,904,420.2a15.71,15.71,0,0,0,1.85,1.15,10.87,10.87,0,0,0,1.95.71,7.42,7.42,0,0,0,1.92.23,3.77,3.77,0,0,0,2-.5,1.57,1.57,0,0,0,.77-1.36,1.27,1.27,0,0,0-.44-1,3.54,3.54,0,0,0-.94-.58c-.23-.06-.6-.17-1.14-.27s-1.14-.17-1.85-.23a1.11,1.11,0,0,1-.34,0l-.33,0c-.91-.1-1.65-.2-2.19-.31a5.85,5.85,0,0,1-1.41-.4,3.62,3.62,0,0,1-1-.58,3.1,3.1,0,0,1-.94-1.08,2.62,2.62,0,0,1-.34-1.32,3.1,3.1,0,0,1,.64-1.89A4.51,4.51,0,0,1,904,411.3a5.74,5.74,0,0,1,2.42-.51,8.05,8.05,0,0,1,3.67.95c.5.27,1,.54,1.34.74l1.85-1.62h.1v3.72h-.24l-.9-.91a6.43,6.43,0,0,0-.74-.61,9.48,9.48,0,0,0-1.68-1,8.87,8.87,0,0,0-1.75-.65,8.06,8.06,0,0,0-1.72-.23,4.69,4.69,0,0,0-1.44.2,2.31,2.31,0,0,0-1,.57,1.24,1.24,0,0,0-.37.85,1.07,1.07,0,0,0,.41.81,2.32,2.32,0,0,0,.84.51,10.19,10.19,0,0,0,1,.23c.5.11,1,.17,1.65.24a.47.47,0,0,1,.23,0,.44.44,0,0,1,.2,0c.07,0,.14,0,.17,0l.64.1a24.67,24.67,0,0,1,2.72.51,5.05,5.05,0,0,1,1.65.85,3.27,3.27,0,0,1,1.41,2.7,4.16,4.16,0,0,1-.27,1.42,3.72,3.72,0,0,1-.8,1.19,3.94,3.94,0,0,1-1.58.94,6.9,6.9,0,0,1-2.19.34,7.74,7.74,0,0,1-1.31-.1,6.78,6.78,0,0,1-1.28-.34,9.44,9.44,0,0,1-1.44-.61l-1.62-.77Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M816.25,433.18v-3.35h1a3.72,3.72,0,0,1,.67.07,2.07,2.07,0,0,1,.47.24,2.32,2.32,0,0,1,.51.57,1.61,1.61,0,0,1,.16.78,2.05,2.05,0,0,1-.16.78,1.51,1.51,0,0,1-.48.57,1.73,1.73,0,0,1-.47.24,1.86,1.86,0,0,1-.67.07h-1Zm2.32-1.69a1.37,1.37,0,0,0-.1-.54,1,1,0,0,0-.3-.41,1,1,0,0,0-.37-.2,2.69,2.69,0,0,0-.58-.07h-.5v2.44h.5a2.18,2.18,0,0,0,.58-.07,1.33,1.33,0,0,0,.37-.2,1,1,0,0,0,.3-.41A1.37,1.37,0,0,0,818.57,431.49Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M823,431.59v1.59h-.47v-3.35h1.17a1,1,0,0,1,.37,0,.75.75,0,0,1,.27.13.78.78,0,0,1,.24.31.82.82,0,0,1,.1.37,1,1,0,0,1-.1.41,1.17,1.17,0,0,1-.24.3c-.07,0-.1.07-.17.1a.37.37,0,0,1-.2.07l1.21,1.59h-.57l-1.18-1.59H823Zm.64-.44a.66.66,0,0,0,.26,0,.42.42,0,0,0,.14-.1.43.43,0,0,0,.13-.31.36.36,0,0,0,0-.17.49.49,0,0,0-.1-.13l-.14-.1a.45.45,0,0,0-.26,0H823v.88Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M829.49,431.63v1.52H829v-1.52l-1.14-1.83h.54l.84,1.35.84-1.35h.54Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M833.86,432.34a1,1,0,0,0,.37.3,1.12,1.12,0,0,0,.48.14.55.55,0,0,0,.23,0l.2-.1c.07,0,.1-.1.14-.17a.46.46,0,0,0,0-.2.38.38,0,0,0-.1-.3,1.29,1.29,0,0,0-.3-.21c-.1-.07-.24-.1-.37-.17a1.1,1.1,0,0,1-.37-.2.69.69,0,0,1-.31-.3,1.07,1.07,0,0,1-.13-.44.86.86,0,0,1,.07-.34,1.46,1.46,0,0,1,.2-.27,2.53,2.53,0,0,1,.34-.21,1.76,1.76,0,0,1,.4-.06,1,1,0,0,1,.3,0,1.15,1.15,0,0,1,.24.07l.2.1c.07,0,.1.07.17.1l-.27.34a.81.81,0,0,0-.61-.24.38.38,0,0,0-.2,0c-.07,0-.13,0-.17.1s-.1.1-.13.13a.39.39,0,0,0,0,.21.39.39,0,0,0,.11.27,1.18,1.18,0,0,0,.3.2,3.3,3.3,0,0,0,.37.17,1.46,1.46,0,0,1,.37.2,1,1,0,0,1,.17.14.33.33,0,0,1,.13.17c0,.06.07.13.1.2a.74.74,0,0,1,0,.27,2,2,0,0,1-.06.41.82.82,0,0,1-.24.3,1,1,0,0,1-.33.2,1.47,1.47,0,0,1-.41.07,1.41,1.41,0,0,1-.64-.13,2.09,2.09,0,0,1-.53-.41Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M839.24,433.18v-3.35h1a3.62,3.62,0,0,1,.67.07,2.39,2.39,0,0,1,.48.24,2.28,2.28,0,0,1,.5.57,1.62,1.62,0,0,1,.17.78,2.06,2.06,0,0,1-.17.78,1.58,1.58,0,0,1-.47.57,1.64,1.64,0,0,1-1.14.31h-1Zm2.32-1.69a1.37,1.37,0,0,0-.1-.54,1,1,0,0,0-.3-.41.89.89,0,0,0-.37-.2,2.59,2.59,0,0,0-.57-.07h-.51v2.44h.51a2.1,2.1,0,0,0,.57-.07,1.2,1.2,0,0,0,.37-.2.9.9,0,0,0,.3-.41A1.37,1.37,0,0,0,841.56,431.49Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M847.28,432.3h-1.41l-.34.88H845l1.37-3.35h.34l1.38,3.35h-.51Zm-1.21-.44h1l-.5-1.25Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M851.92,432.71h1.48v.44h-1.95V429.8h.47Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M858.37,431.15v.44h-1.11v1.12h1.48v.44h-2V429.8h1.92v.44h-1.45v.88h1.11Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M838.07,411.37c2.45,0,4,1.32,4.3,3h.13v-3.32H829.16v.11l1.91,1.21v8.83l-1.91,1.22v.1h13.31V419h-.13a4.14,4.14,0,0,1-4.31,3.25h-3.29v-5.45h3.13l1.21,1.93h.1v-4.16h-.1l-1.21,1.93h-3.13v-5.11Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M794.53,422.66a7,7,0,0,1-2.49-.47,5.59,5.59,0,0,1-2-1.28,3.12,3.12,0,0,1-.94-2.24,3.64,3.64,0,0,1,1.61-2.9,5.33,5.33,0,0,1,3.46-1.15,4.32,4.32,0,0,1,2.32.6,10.22,10.22,0,0,1,.94.58l1.31-1.15h.11v2.94h-.17a10.79,10.79,0,0,0-.88-1,5.36,5.36,0,0,0-1.61-1.15,4.08,4.08,0,0,0-1.78-.44,2.21,2.21,0,0,0-.81.17,1.91,1.91,0,0,0-.67.44,4.16,4.16,0,0,0-.91,3,7.12,7.12,0,0,0,.07.92,3.43,3.43,0,0,0,.2.88,2.63,2.63,0,0,0,.3.67,2,2,0,0,0,.61.71,2.31,2.31,0,0,0,.8.44,3.18,3.18,0,0,0,1,.14,4.27,4.27,0,0,0,1.11-.17,3.91,3.91,0,0,0,1-.48A3.65,3.65,0,0,0,798,421a2.74,2.74,0,0,0,.47-.6c.1-.21.23-.44.37-.78v0l.23.1v0a8.45,8.45,0,0,1-.37.81,5.42,5.42,0,0,1-.47.64A4.71,4.71,0,0,1,794.53,422.66Z" transform="translate(1.91 1.61)"/>
        </g>
        <g id="Bayview_Central_Curlewis" data-name="Bayview Central Curlewis">
            <circle class="cls-7" cx="733.37" cy="411.39" r="9.01"/>
            <path class="cls-8" d="M438,413.31c0,1.92-1.52,2.88-4,2.88H426.8v-10.5h7c2.13,0,3.65.9,3.65,2.67a2.31,2.31,0,0,1-2.25,2.43C436.82,410.89,438,411.55,438,413.31Zm-10-3.06h5.56c1.5,0,2.57-.39,2.57-1.71s-1.07-1.71-2.57-1.71h-5.56Zm8.68,3c0-1.31-1.06-1.83-2.86-1.83h-5.82v3.66h5.82C435.67,415.05,436.73,414.51,436.73,413.22Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M448.9,413.37h-7.08l-1.53,2.82h-1.4l5.78-10.5h1.41l5.77,10.5h-1.42Zm-.62-1.14-2.92-5.39-2.93,5.39Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M452.41,405.69l4.41,5.68,4.38-5.68h1.5l-5.27,6.82v3.68h-1.26v-3.68l-5.28-6.82Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M464.63,405.69l4.85,9.13,4.84-9.13h1.4l-5.57,10.5h-1.38l-5.56-10.5Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M477.7,416.19v-10.5H479v10.5Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M492.59,415v1.17H482.44v-10.5h10v1.17H483.7v3.4h7.41v1.17H483.7V415Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M511,405.69l-3.85,10.5h-1.34l-3.37-9-3.39,9h-1.34l-3.85-10.5h1.38l3.16,9,3.41-9h1.27l3.39,9,3.18-9Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M527.26,413.32l1.2.6a6,6,0,0,1-5.39,2.58c-3.91,0-6.31-2.16-6.31-5.56s2.4-5.57,6.36-5.57a6,6,0,0,1,5.34,2.58l-1.2.6a4.53,4.53,0,0,0-4.16-2c-3,0-5.05,1.5-5.05,4.4s2,4.39,5.05,4.39A4.47,4.47,0,0,0,527.26,413.32Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M541,415v1.17H530.84v-10.5h10v1.17H532.1v3.4h7.41v1.17H532.1V415Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M554.75,405.69v10.5h-1.11l-8.82-8.9v8.9h-1.23v-10.5H545l8.56,8.65v-8.65Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M568,406.83h-5v9.36h-1.26v-9.36h-4.95v-1.14H568Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M579.82,416.19l-3.48-4h-5v4h-1.27v-10.5h7c2.34,0,4,1.17,4,3.24,0,1.83-1.32,3-3.26,3.19l3.59,4.07ZM577,411c1.69,0,2.82-.65,2.82-2.07s-1.13-2.07-2.82-2.07h-5.7V411Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M592.39,413.37h-7.08l-1.53,2.82h-1.4l5.78-10.5h1.41l5.77,10.5h-1.42Zm-.62-1.14-2.92-5.39-2.93,5.39Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M606.62,415v1.17h-9.3v-10.5h1.26V415Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M622.82,413.32l1.2.6a6,6,0,0,1-5.39,2.58c-3.91,0-6.31-2.16-6.31-5.56s2.4-5.57,6.36-5.57A6,6,0,0,1,624,408l-1.2.6a4.5,4.5,0,0,0-4.15-2c-3,0-5.06,1.5-5.06,4.4s2,4.39,5.06,4.39A4.45,4.45,0,0,0,622.82,413.32Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M637.28,405.69v5.55c0,3.06-1.92,5.26-5.53,5.26s-5.55-2.2-5.55-5.26v-5.55h1.26v5.47c0,2.58,1.54,4.17,4.29,4.17s4.27-1.59,4.27-4.17v-5.47Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M650.3,416.19l-3.48-4h-5v4h-1.27v-10.5h7.05c2.34,0,4,1.17,4,3.24,0,1.83-1.32,3-3.26,3.19l3.59,4.07ZM647.51,411c1.69,0,2.82-.65,2.82-2.07s-1.13-2.07-2.82-2.07h-5.7V411Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M663.72,415v1.17h-9.3v-10.5h1.26V415Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M676,415v1.17H665.81v-10.5h10v1.17h-8.74v3.4h7.41v1.17h-7.41V415Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M694.41,405.69l-3.85,10.5h-1.34l-3.37-9-3.39,9h-1.34l-3.85-10.5h1.38l3.16,9,3.41-9h1.27l3.39,9,3.18-9Z" transform="translate(1.91 1.61)"/>
            <path class="cls-8" d="M706.85,413.14c0,1.94-2,3.36-5.39,3.36a9.22,9.22,0,0,1-6.13-2.16l.88-.94a7.59,7.59,0,0,0,5.33,1.93c2.53,0,4-.79,4-2.1s-1.55-1.59-4.38-1.83-5.49-.85-5.49-2.92,2.49-3.11,5.29-3.11a8.06,8.06,0,0,1,5.37,1.88l-1,.84a6.14,6.14,0,0,0-4.32-1.55c-1.87,0-4.06.51-4.06,1.89s2,1.58,4.45,1.77C704.63,410.46,706.85,411.07,706.85,413.14Z" transform="translate(1.91 1.61)"/>
        </g>
    </g>
    <g id="Locations">
        <rect class="cls-4" x="1588.51" y="817.12" width="284.72" height="218.81"/>
        <circle class="cls-10" cx="1628.7" cy="859.68" r="12.14"/>
        <circle class="cls-11" cx="1628.7" cy="902.61" r="12.14"/>
        <circle class="cls-7" cx="1628.7" cy="945.53" r="12.14"/>
        <circle class="cls-12" cx="1628.7" cy="988.46" r="12.14"/>
        <path class="cls-8" d="M1673.27,861.56l-3.68-4.21H1664v4.21h-.8v-10.5h6.89c2.34,0,3.9,1.17,3.9,3.14s-1.35,3-3.41,3.13l3.74,4.23Zm-3.26-4.93c1.94,0,3.15-.86,3.15-2.43s-1.21-2.41-3.15-2.41H1664v4.84Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1686.87,860.83v.73H1677v-10.5h9.73v.73h-9v4.06h7.68v.73h-7.68v4.25Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1699.31,859.06l.73.37a5.87,5.87,0,0,1-5.25,2.45c-3.9,0-6.22-2.19-6.22-5.57s2.32-5.56,6.25-5.56a5.87,5.87,0,0,1,5.22,2.44l-.73.38a5,5,0,0,0-4.5-2.09c-3.29,0-5.45,1.71-5.45,4.83s2.16,4.83,5.45,4.83A5,5,0,0,0,1699.31,859.06Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1712.78,861.56l-3.68-4.21h-5.62v4.21h-.8v-10.5h6.89c2.34,0,3.9,1.17,3.9,3.14s-1.35,3-3.41,3.13l3.74,4.23Zm-3.26-4.93c1.94,0,3.15-.86,3.15-2.43s-1.21-2.41-3.15-2.41h-6.06v4.84Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1726.38,860.83v.73h-9.88v-10.5h9.73v.73h-9v4.06H1725v.73h-7.68v4.25Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1737.68,858.46h-7.43l-1.72,3.1h-.87l5.88-10.5h.87l5.88,10.5h-.87Zm-.39-.72-3.33-6-3.32,6Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1750.25,851.77h-5.13v9.79h-.78v-9.79h-5.12v-.71h11Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1752.5,861.56v-10.5h.78v10.5Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1756.22,856.31c0-3.39,2.32-5.56,6.27-5.56s6.26,2.17,6.26,5.56-2.32,5.57-6.26,5.57S1756.22,859.7,1756.22,856.31Zm11.73,0c0-3.07-2.16-4.83-5.46-4.83s-5.48,1.76-5.48,4.83,2.16,4.83,5.48,4.83S1768,859.39,1768,856.31Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1782.51,851.06v10.5h-.69l-9.37-9.51v9.51h-.77v-10.5h.87l9.2,9.35v-9.35Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1672.7,896.17h-5.13V906h-.78v-9.79h-5.12v-.71h11Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1685,906l-3.67-4.21h-5.63V906H1675v-10.5h6.88c2.34,0,3.9,1.17,3.9,3.14s-1.35,3-3.4,3.14l3.73,4.22Zm-3.25-4.93c1.93,0,3.15-.86,3.15-2.43s-1.22-2.41-3.15-2.41h-6.06V901Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1697.21,902.86h-7.43l-1.72,3.1h-.87l5.88-10.5h.87l5.87,10.5H1699Zm-.39-.72-3.33-5.95-3.32,5.95Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1712.85,895.46V906h-.69l-9.37-9.5V906H1702v-10.5h.87l9.19,9.35v-9.35Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1726.84,903c0,1.83-1.88,3.24-5.32,3.24a8,8,0,0,1-6-2.25l.57-.56a7,7,0,0,0,5.43,2.07c2.9,0,4.5-1,4.5-2.46s-1.76-1.86-4.77-2.14c-2.71-.25-5.35-.82-5.35-2.81s2.52-3,5.22-3a7.05,7.05,0,0,1,5.14,1.85l-.62.5a6,6,0,0,0-4.49-1.62c-2,0-4.44.7-4.44,2.24s2.19,1.84,4.81,2.09C1724.69,900.47,1726.84,901.08,1726.84,903Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1740.3,898.69c0,2-1.51,3.24-3.9,3.24h-6.09v4h-.78v-10.5h6.87C1738.79,895.46,1740.3,896.7,1740.3,898.69Zm-.8,0c0-1.61-1.2-2.5-3.15-2.5h-6v5h6C1738.3,901.21,1739.5,900.31,1739.5,898.69Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1742.1,900.71c0-3.38,2.32-5.56,6.27-5.56s6.27,2.18,6.27,5.56-2.33,5.57-6.27,5.57S1742.1,904.1,1742.1,900.71Zm11.73,0c0-3.07-2.16-4.83-5.46-4.83s-5.47,1.76-5.47,4.83,2.15,4.83,5.47,4.83S1753.83,903.79,1753.83,900.71Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1767.66,906l-3.67-4.21h-5.63V906h-.79v-10.5h6.88c2.34,0,3.9,1.17,3.9,3.14s-1.35,3-3.4,3.14l3.73,4.22Zm-3.25-4.93c1.93,0,3.14-.86,3.14-2.43s-1.21-2.41-3.14-2.41h-6.06V901Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1780.46,896.17h-5.13V906h-.78v-9.79h-5.12v-.71h11Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1664,940.58v4.29h7.68v.74H1664v4.75h-.78v-10.5h9.72v.72Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1674.17,945.11c0-3.39,2.32-5.56,6.27-5.56s6.27,2.17,6.27,5.56-2.33,5.57-6.27,5.57S1674.17,948.5,1674.17,945.11Zm11.73,0c0-3.07-2.16-4.82-5.46-4.82S1675,942,1675,945.11s2.16,4.83,5.48,4.83S1685.9,948.19,1685.9,945.11Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1688.82,945.11c0-3.39,2.33-5.56,6.27-5.56s6.27,2.17,6.27,5.56-2.32,5.57-6.27,5.57S1688.82,948.5,1688.82,945.11Zm11.73,0c0-3.07-2.16-4.82-5.46-4.82s-5.47,1.75-5.47,4.82,2.16,4.83,5.47,4.83S1700.55,948.19,1700.55,945.11Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1715.4,945.11c0,3.21-2.43,5.25-6.22,5.25h-4.89v-10.5h4.89C1713,939.86,1715.4,941.9,1715.4,945.11Zm-.79,0c0-2.94-2.3-4.53-5.48-4.53h-4.06v9h4.06C1712.31,949.63,1714.61,948.05,1714.61,945.11Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1733.25,950.36l-1.5-1.05a6.22,6.22,0,0,1-4.27,1.35c-3,0-5-1.48-5-3.42a3.1,3.1,0,0,1,2.35-2.91,2.64,2.64,0,0,1-1.06-2c0-1.63,1.5-2.73,3.72-2.73a4.31,4.31,0,0,1,4.3,2.7l-.69.29a3.57,3.57,0,0,0-3.63-2.28c-1.8,0-2.89.81-2.89,2s1,1.76,2.23,2.63l4.88,3.46a4.48,4.48,0,0,0,.88-2.88h.77a5.35,5.35,0,0,1-1,3.32l2.23,1.57Zm-2.11-1.48-5-3.59c-.26-.16-.51-.34-.74-.51-1.48.47-2.11,1.35-2.11,2.42,0,1.71,1.83,2.74,4.27,2.74A5.5,5.5,0,0,0,1731.14,948.88Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1751.73,947.44c0,1.83-1.89,3.24-5.32,3.24a8,8,0,0,1-6-2.25l.57-.56a7,7,0,0,0,5.43,2.07c2.89,0,4.5-1,4.5-2.46s-1.76-1.86-4.77-2.14c-2.72-.26-5.36-.83-5.36-2.81s2.52-3,5.22-3a7.16,7.16,0,0,1,5.15,1.84l-.62.51a6,6,0,0,0-4.5-1.61c-2,0-4.44.68-4.44,2.23s2.19,1.84,4.82,2.08C1749.57,944.87,1751.73,945.47,1751.73,947.44Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1765.25,939.86v10.5h-.78v-5h-9.27v5h-.78v-10.5h.78v4.8h9.27v-4.8Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1768.19,945.11c0-3.39,2.32-5.56,6.27-5.56s6.27,2.17,6.27,5.56-2.33,5.57-6.27,5.57S1768.19,948.5,1768.19,945.11Zm11.73,0c0-3.07-2.16-4.82-5.46-4.82S1769,942,1769,945.11s2.16,4.83,5.48,4.83S1779.92,948.19,1779.92,945.11Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1794.42,943.09c0,2-1.51,3.24-3.9,3.24h-6.09v4h-.78v-10.5h6.87C1792.91,939.86,1794.42,941.09,1794.42,943.09Zm-.79,0c0-1.61-1.2-2.51-3.15-2.51h-6v5h6C1792.43,945.61,1793.63,944.71,1793.63,943.09Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1807.8,943.09c0,2-1.51,3.24-3.9,3.24h-6.09v4H1797v-10.5h6.87C1806.29,939.86,1807.8,941.09,1807.8,943.09Zm-.79,0c0-1.61-1.2-2.51-3.15-2.51h-6v5h6C1805.81,945.61,1807,944.71,1807,943.09Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1810.41,950.36v-10.5h.78v10.5Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1825.77,939.86v10.5h-.69l-9.37-9.51v9.51h-.77v-10.5h.87l9.2,9.35v-9.35Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1840.88,947.59c-.78,1.77-2.9,3.09-5.84,3.09-4,0-6.33-2.19-6.33-5.57s2.33-5.56,6.3-5.56a6.34,6.34,0,0,1,5.28,2.23l-.67.47a5.42,5.42,0,0,0-4.61-2c-3.34,0-5.5,1.69-5.5,4.82s2.17,4.83,5.55,4.83c2.31,0,4.24-.84,5-2.56v-1.59h-5.16v-.72h5.94Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1673.06,994v.73h-9.89v-10.5h9.74V985h-9v4.06h7.68v.74H1664V994Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1687,989.51c0,3.21-2.43,5.25-6.22,5.25h-4.89v-10.5h4.89C1684.56,984.26,1687,986.3,1687,989.51Zm-.79,0c0-2.93-2.3-4.52-5.48-4.52h-4.06v9h4.06C1683.9,994,1686.2,992.46,1686.2,989.51Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1700.51,984.26v5.55c0,3-1.86,5.27-5.39,5.27s-5.4-2.25-5.4-5.27v-5.55h.78v5.51a4.62,4.62,0,0,0,9.23,0v-5.51Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1714,992.26l.73.37a5.87,5.87,0,0,1-5.25,2.45c-3.9,0-6.22-2.19-6.22-5.57s2.32-5.56,6.25-5.56a5.87,5.87,0,0,1,5.22,2.44l-.73.38a5,5,0,0,0-4.5-2.08c-3.29,0-5.45,1.7-5.45,4.82s2.16,4.83,5.45,4.83A5,5,0,0,0,1714,992.26Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1725.68,991.66h-7.43l-1.72,3.1h-.87l5.88-10.5h.87l5.88,10.5h-.87Zm-.39-.72-3.33-6-3.32,6Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1738.25,985h-5.13v9.79h-.78V985h-5.12v-.71h11Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1740.5,994.76v-10.5h.78v10.5Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1744.22,989.51c0-3.39,2.32-5.56,6.27-5.56s6.27,2.17,6.27,5.56-2.33,5.57-6.27,5.57S1744.22,992.9,1744.22,989.51Zm11.73,0c0-3.07-2.16-4.82-5.46-4.82s-5.48,1.75-5.48,4.82,2.16,4.83,5.48,4.83S1756,992.59,1756,989.51Z" transform="translate(1.91 1.61)"/>
        <path class="cls-8" d="M1770.51,984.26v10.5h-.69l-9.37-9.51v9.51h-.77v-10.5h.87l9.2,9.35v-9.35Z" transform="translate(1.91 1.61)"/>
        <g id="Saint_Ignatius_College_Geelong" data-name="Saint Ignatius College Geelong">
            <circle class="cls-12" cx="834.61" cy="705.38" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Bellarine_Secondary_College" data-name="Bellarine Secondary College">
            <circle class="cls-12" cx="823.07" cy="793.26" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="St_Thomas_Catholic_Primary_School" data-name="St Thomas Catholic Primary School">
            <circle class="cls-12" cx="852.41" cy="686.86" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Christian_College_Geelong" data-name="Christian College Geelong">
            <circle class="cls-12" cx="1042.15" cy="448.11" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Drysdale_Primary_School" data-name="Drysdale Primary School">
            <circle class="cls-12" cx="1026.55" cy="417.96" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Clifton_Spring_Primary_School" data-name="Clifton Spring Primary School">
            <circle class="cls-12" cx="803.12" cy="369.42" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Drysdale_Train_Station" data-name="Drysdale Train Station">
            <circle class="cls-11" cx="850.28" cy="587.06" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Clifton_Springs_Gold_COurse" data-name="Clifton Springs Golf Course">
            <circle class="cls-10" cx="964.44" cy="178.07" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Curlewis_Golf_CLub" data-name="Curlewis Golf CLub">
            <circle class="cls-10" cx="584.68" cy="605.51" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Clifton_Springs_Tennis_Club" data-name="Clifton Springs Tennis Club">
            <circle class="cls-10" cx="811.67" cy="317.22" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Adventure_Park_Geelong" data-name="Adventure Park Geelong">
            <circle class="cls-10" cx="278.17" cy="1017.41" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Bellarine_Basketball_Stadium" data-name="Bellarine Basketball Stadium">
            <circle class="cls-10" cx="853.89" cy="738.42" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Drysdale_Leopold_Pony_Club" data-name="Drysdale Leopold Pony Club">
            <circle class="cls-10" cx="897.43" cy="624.11" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Clifton_Springs_Foreshore_Reserve" data-name="Clifton Springs Foreshore Reserve">
            <circle class="cls-10" cx="804.17" cy="210.22" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Harley_s_Beach" data-name="Harley’s Beach">
            <circle class="cls-10" cx="1028.97" cy="65.2" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Drysdale_Recreation_Reserve" data-name="Drysdale Recreation Reserve">
            <circle class="cls-10" cx="840.47" cy="523.47" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Lake_Lorne_Reserve" data-name="Lake Lorne Reserve">
            <circle class="cls-10" cx="841.95" cy="639.56" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Drysdale_Sports_Precinct" data-name="Drysdale Sports Precinct">
            <circle class="cls-10" cx="787.71" cy="824.42" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Drysdale_Soccer_Club" data-name="Drysdale Soccer Club">
            <circle class="cls-10" cx="815.06" cy="842.44" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="The_Whiskey" data-name="The Whiskery">
            <circle class="cls-7" cx="1308.02" cy="93.28" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Curlewis_Winery" data-name="Curlewis Winery">
            <circle class="cls-7" cx="321.51" cy="418.39" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Tuckerberry_Hill" data-name="Tuckerberry Hill">
            <circle class="cls-7" cx="1597.52" cy="526.88" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="The_Range_Curlewis" data-name="The Range @ Curlewis">
            <circle class="cls-7" cx="420.47" cy="590.47" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Jack_Rabbit" data-name="Jack Rabbit">
            <circle class="cls-7" cx="1293.15" cy="17.76" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
        <g id="Scotchmans_Hill" data-name="Scotchmans Hill">
            <circle class="cls-7" cx="1474.95" cy="182.95" r="9.01" onMouseEnter={e => this.updateText(e)} onMouseLeave={e => this.clearText(e)}/>
        </g>
    </g>
</svg>
</section>
)
}
}

export default MapComponentHover;