import React from 'react';
import './style.scss'

class ImageCollage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverText: '',
      isChanging: false,
      showPopup:false,
      popup: {
        title:'',
        description:'',
        imageUrl:'',
        website:''
      }
    };
  }

  changePopup = (copy,link,title,image) => {
    console.log(copy,link,title,image)
    this.setState({
      showPopup:true,
      popup:{
        title:title,
        description: copy,
        imageUrl:image,
        website: link
      }
    })
  }
  
  closePopup = (e) => {
    this.setState({
      showPopup:false
    })
  }

  
  render(){
    const arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="17.369" height="10.87" viewBox="0 0 17.369 10.87">
<g id="CTA_Arrow" data-name="CTA Arrow" transform="translate(-1102.426 -966.653)">
  <line id="Line_25" data-name="Line 25" y2="15.17" transform="translate(1102.426 971.989) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,4.9,4.9,9.809,0" transform="translate(1113.83 976.992) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
</g>
</svg>`
    return (
      <section className="ImageCollageComponent">
        <div className="container">
          <h4 className="heading" dangerouslySetInnerHTML={{ __html: this.state.hoverText ? this.state.hoverText : this.props.heading }}/>
        </div>
          <div className="images">
            {this.props.collage_images.map(({copy, link, title, image}) => {
              return (
                <div className="images_image" onClick={e => this.changePopup(copy,link,title,image)}>
                  <img src={image ? image.source_url : ''} />
                </div>
              )
            })}            
          </div>
          <div className={`image_popup ${this.state.showPopup ? 'active' : ''}`}>
          <button type="button" id="close_popup" onClick={e => this.closePopup()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
                <path class="a"
                    d="M28.523,9.623l-2.1-2.1-8.4,8.4-8.4-8.4-2.1,2.1,8.4,8.4-8.4,8.4,2.1,2.1,8.4-8.4,8.4,8.4,2.1-2.1-8.4-8.4Z"
                    transform="translate(29.523 30.477)" />
                <g class="b">
                    <circle class="c" cx="48" cy="48" r="48" />
                    <circle class="d" cx="48" cy="48" r="47.5" />
                </g>
            </svg>
        </button>            
          <div class="container">
                    <h2 class="image_popup_explore">
                        Explore the area
                    </h2>
                <div class="image_popup_image">
                  <img src={this.state.popup.imageUrl.source_url} />
                </div>
                <div class="image_popup_content">
                    <h4 id="image_popup_title" class="image_popup_title">{this.state.popup.title}</h4>
                    <div className="image_popup_description" dangerouslySetInnerHTML={{ __html: this.state.popup.description }}/>
                    <a href={this.state.popup.website} id="image_popup_website" class="image_popup_website" target="_blank">
                    <span>Visit Website</span>
                    <span dangerouslySetInnerHTML={{ __html: arrow }} />
                    </a>
                </div>
        </div>
          </div>
      </section>
    )
  }
}

export default ImageCollage;