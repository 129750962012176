import React from 'react';
import axios from 'axios'

import './_register.scss'

const arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="17.369" height="10.87" viewBox="0 0 17.369 10.87">
<g id="CTA_Arrow" data-name="CTA Arrow" transform="translate(-1102.426 -966.653)">
  <line id="Line_25" data-name="Line 25" y2="15.17" transform="translate(1102.426 971.989) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,4.9,4.9,9.809,0" transform="translate(1113.83 976.992) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
</g>
</svg>`

class ConsumerFormComponent extends React.Component {
  constructor(props) {
    super(props);
    // Set state of all fields using the input name (important!)
    this.state = {
      formSent: false,
      sending:false,
      successMessage:'',
      formLoaded:false,
      formDescription: '',
      formApi: [],
      formThankYou:''
    }
  }

  getFormFields = async () => {
    try {
        const {data} = await axios.get(`https://www.mcleodsdrysdale.com.au/cms/wp-json/site/forms?id=1`);
        return data;
    } catch (err) {
        console.log(err.message);
    }
}

async componentWillMount() {
  await this.getFormFields().then((fields) => {
    const array = []
    fields.fields.map(field => array.push(field))
    var result = Object.entries(fields.confirmations);

    this.setState({
      formDescription: fields.description,
      successMessage: result[0][1].message,
      formApi: array,
      formLoaded: true
    });
  })
}

  render() {
    const onSubmit = (event) => {
      event.preventDefault();
      this.setState({
        sending:true
      })
      let formData = new FormData(event.target)
			const form = Object.fromEntries(formData)
			var data = {};
			formData.forEach(function(value, key){
				data[key] = value;
			});
			
      console.log(form)
      const submitUrl = `https://www.mcleodsdrysdale.com.au/cms/wp-json/gf/v2/forms/1/submissions`
      axios.post(submitUrl, data )
        .then((result) => {
          setTimeout(() => {
            this.setState({
              sending:false,
              formSent: true,
            })
          }, 500);
        });
    }

    const fields = this.state.formApi
    const successMessage = this.state.successMessage
    return(
      <div className="register__form consumer">
        {(() => {
          if(!this.state.formSent){
            return(
              <form onSubmit={onSubmit} className={this.state.formSent ? 'd-none' : ''}>
              {this.state.formLoaded ? fields.map((field) => {
                if(field.type === 'text'){
                  return(
                    <div className={`form-group ${field.size}`}>
                      <label>{field.label}</label>
                      <input type={field.type} placeholder={field.placeholder} name={`input_${field.id}`} required={field.isRequired ? true : false}/>
                    </div>
                  )
                } else if(field.type === 'email') {
                  return(
                    <div className={`form-group ${field.size}`}>
                      <label>{field.label}</label>
                      <input type={field.type} placeholder={field.placeholder} name={`input_${field.id}`} required={field.isRequired ? true : false}/>
                    </div>
                  )
                } else if(field.type === 'textarea') {
                  return(
                    <div className={`form-group ${field.size}`}>
                      <label>{field.label}</label>
                      <textarea type={field.type} placeholder={field.placeholder} name={`input_${field.id}`} required={field.isRequired ? true : false}/>
                    </div>
                  )
                } else{
                  return(
                    <div className={`form-group ${field.size}`}>
                      <label>{field.label}</label>
                      <select name={`input_${field.id}`}  required={field.isRequired ? true : false}>
                      {(() => {
                          if(field.placeholder) {
                              return(
                                <option value="" disabled selected>{field.placeholder}</option>
                              )
                          }
                      })()}
                      {field.choices.map((option) => {
                        return(
                          <option value={option.value}>{option.text}</option>
                        )
                      })}
                      </select>
                    </div>
                  )
                }
              }) : 'Form Loading...'}
              {(() => {
                if(this.state.formLoaded) {
                  if(this.state.sending){
                    return(
                      <button type="submit" disabled>Sending...</button>
                    )
                  } else {
                    return(
                      <button type="submit">Submit Form <span dangerouslySetInnerHTML={{ __html: arrow }} /></button>
                    )
                  }
                }
            })()}
            </form>
            )
          } else {
            return(
      <div className={this.state.formSent ? 'thanks show' : 'thanks hidden'} dangerouslySetInnerHTML={{ __html: successMessage }}/>
            )
          }
      })()}
      </div>
    )
  }
}

export default ConsumerFormComponent;