import React from 'react';
import {graphql, Link} from 'gatsby';
import Img from 'gatsby-image'
import './style.scss'

export const fragment = graphql`
  fragment PageHeroComponent on WordPressAcf_page_hero {
    content
    image {
      localFile {
        childImageSharp {
          fluid {
            srcSetWebp
            srcWebp
            srcSet
          }
        }
      }
    }
  }
`;

const arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="50.369" height="10.87" viewBox="0 0 50.369 10.87">
<g id="CTA_Arrow" data-name="CTA Arrow" transform="translate(-1102.426 -966.653)">
  <line id="Line_25" data-name="Line 25" y2="48" transform="translate(1102.426 971.989) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,4.9,4.9,9.809,0" transform="translate(1146.83 976.992) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
</g>
</svg>
`

const PageHeroComponent = ({
  content,
  image
}) => (
  <section className="PageHeroComponent">
    {(() => {
      if(image){
        return <Img fluid={image.localFile.childImageSharp.fluid} />
      }
    })()}
    <div className="container">
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <Link to="/">Go Home <span dangerouslySetInnerHTML={{ __html: arrow }} /></Link>
    </div>
  </section>
);

export default PageHeroComponent;