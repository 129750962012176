import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image'
import Slider from "react-slick";

import './style.scss'
import '../../../assets/styles/modules/slick.css';
import '../../../assets/styles/modules/slick-theme.css';

export const fragment = graphql`
  fragment LifestyleBlockComponent on WordPressAcf_lifestyle_block {
    heading
    layout_type
  }
`;

const lifestyleItems = graphql`
  {
    allWordpressWpLifestyleItem(sort: {fields: modified, order: DESC}) {
      edges {
        node {
          title
          content
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  src
                  srcSet
                  srcSetWebp
                }
              }
            }
          }
          acf {
            external_link
          }
        }
      }
    }
  }
`

const arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="17.369" height="10.87" viewBox="0 0 17.369 10.87">
<g id="CTA_Arrow" data-name="CTA Arrow" transform="translate(-1102.426 -966.653)">
  <line id="Line_25" data-name="Line 25" y2="15.17" transform="translate(1102.426 971.989) rotate(-90)" fill="none" stroke="#404a3d" stroke-width="1.5"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,4.9,4.9,9.809,0" transform="translate(1113.83 976.992) rotate(-90)" fill="none" stroke="#404a3d" stroke-width="1.5"/>
</g>
</svg>`

var settings = {
  arrows:false,
  autoplay:false,
  dots: false,
  draggable:true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  adaptiveHeight:true,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      }
    },
  ]
}

const LifestyleBlockComponent = ({
  layout_type,
  heading,
  data
}) => (
  <section className="LifestyleBlockComponent" data-layout={layout_type}>
    <div className="container-fluid">
      <div className="content__block">
        <StaticQuery
          query={lifestyleItems}
          render={data => {
            if (data) {
              const items = data.allWordpressWpLifestyleItem.edges
              if(layout_type == 'grid'){
                return (
                  <div className="lifestyle__block" data-layout="grid">
                    {items.map(node => {
                      const item = node.node;
                      return(
                      <div className="lifestyle__block-item">
                        <Img fluid={item.featured_media.localFile.childImageSharp.fluid} />
                        <h5 className="lifestyle__block-item-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                        <div className="lifestyle__block-item-content" dangerouslySetInnerHTML={{ __html: item.content }} />
                        <a href={item.acf.external_link} target="_blank">Continue Reading <span dangerouslySetInnerHTML={{ __html: arrow }} /></a>
                      </div>
                      )
                    })}
                  </div>
                )
              } else if(layout_type == 'carousel'){
                  return (
                    <div className="lifestyle__block" data-layout="carousel">
                      <Slider {...settings}>
                      {items.map(node => {
                        const item = node.node;
                        return(
                        <a href={item.acf.external_link} target="_blank" className="lifestyle__block-item">
                          <img src={item.featured_media.localFile.childImageSharp.fluid.src} />
                          <div className="title">{item.title} <span dangerouslySetInnerHTML={{ __html: arrow }} /></div>
                        </a>
                        )
                      })}
                      </Slider>
                    </div>
                  )
              }
            }
          }}
        />
    {/* {content_item.map(({content, column_width}) => {
      return (
        <div className="content__block-item" data-width={column_width}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      )
    })} */}
    </div>
    </div>
  </section>
);



export default LifestyleBlockComponent;