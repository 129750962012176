import React from 'react';
import axios from 'axios'

import './header/_register.scss'

const arrow = `<svg xmlns="http://www.w3.org/2000/svg" width="17.369" height="10.87" viewBox="0 0 17.369 10.87">
<g id="CTA_Arrow" data-name="CTA Arrow" transform="translate(-1102.426 -966.653)">
  <line id="Line_25" data-name="Line 25" y2="15.17" transform="translate(1102.426 971.989) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
  <path id="Path_36" data-name="Path 36" d="M0,0,4.9,4.9,9.809,0" transform="translate(1113.83 976.992) rotate(-90)" fill="none" stroke="currentColor" stroke-width="1.5"/>
</g>
</svg>`

class RunwayForm extends React.Component {
  constructor(props) {
    super(props);
    // Set state of all fields using the input name (important!)
    this.state = {
      formSent: false,
      sending:false,
      formLoaded:false,
      formDescription: '',
      formApi: []
    }
  }

  render() {
    const onSubmit = (event) => {
      event.preventDefault();
      this.setState({
        sending:true
      })
      console.log('submitting...')
      const submitUrl = 'https://red23.runway.com.au/actions/form/globalformaction.jsp'
      let formData = new FormData(event.target)
			const form = Object.fromEntries(formData)
			var data = {};
			formData.forEach(function(value, key){
				data[key] = value;
			});

            console.log(data)
            axios.post(submitUrl, {data} ).then((result) => {
                console.log(result)
              setTimeout(() => {
                this.setState({
                  sending:false,
                  formSent: true,
                })
              }, 500);
            }).catch(err => {
                console.log(err)
            })     
    }

    return(
      <div className="register__form">
        {(() => {
          if(!this.state.formSent){
            return(
<form action="https://red23.runway.com.au/actions/form/globalformaction.jsp" method="post">
    <div className="form-group large">
        <label htmlFor="FirstName">First Name</label>
        <input id="FirstName" name="FirstName"/>
    </div>
    <div className="form-group large">
        <label htmlFor="LastName">Last Name</label>
        <input id="LastName" name="LastName"/>
    </div>
    <div className="form-group large">
        <label htmlFor="Mobile">Mobile Number</label>
        <input id="Mobile" name="Mobile"/>
    </div>
    <div className="form-group large">
        <label htmlFor="Postcode">Postcode</label>
        <input id="Postcode" name="Postcode"/>
    </div>
    <div className="form-group large">
        <label htmlFor="Email">Email Address</label>
        <input id="Email" name="Email"/>
    </div>
<input type="hidden" name="GroupID" value="0L1B6W3A761U9A6N7B4C8A5C8Q2Z"/>                                                                                                                                                                                                                               
<input type="hidden" name="LocationID" value="0B166I367F139S9H4E539I93839I"/> 
<input type="hidden" name="TemplateID" value="00186L3K7J3B0R1M9P7V7V2J1T9S"/>
<input type="hidden" name="NotificationTemplateID" value="0C1Z6Y39798N045I6Y504I9K9F89"/>
<input type="hidden" name="EmailOptOut" id="EmailOptOut" value="N"/>
<input type="hidden" name="NewContactStatusID" value="0P1O343U267Q2A0W94141I4M6E26"/>
<input type="hidden" name="Source" value="Mcleods Drysdale Landing Page"/>                                                                                                                                                                     
<input type="hidden" name="sendNotificationTo" value="SALESREP"/>  
<input type="hidden" name="-redirect"  value="https://mcleods.raakstaging.com/success"/>
 <input type="hidden" name="Answer081C6O3Q7S2E8W7F7X7L57635V22" value="Answer_To_Be_Imported_Into_Runway" />
 <input type="hidden" name="Answer0K11693J7S7T2T8J3N7G3B9J0Y9U" value="Answer_To_Be_Imported_Into_Runway" />
<input type="hidden" name="-alwaysnotify" value="true" />
{(() => {
                  if(this.state.sending){
                    return(
                      <button type="submit" disabled>Sending...</button>
                    )
                  } else {
                    return(
                      <button type="submit">Submit Form <span dangerouslySetInnerHTML={{ __html: arrow }} /></button>
                    )
                  }
            })()}
</form>
            )            
          } else {
            return(
      <div className={this.state.formSent ? 'show' : 'hidden'}>
        <h4>Thanks for your enquiry.</h4>
        <p>We will get back to you as soon as possible.</p>
      </div>
            )
          }
      })()}
      </div>
    )
  }
}

export default RunwayForm;